<template>
  <div class="pa-5">
    <h3>Barcodes</h3>
    <v-card flat outlined class="mb-3">
      <v-card-title>Add from list</v-card-title>
      <v-card-text>
        <v-textarea v-model="addBarcode.list" placeholder="Paste barcodes here" outlined />
        <div class="d-flex">
          <v-spacer />
          <v-btn color="blue" rounded depressed dark @click="addBarcodes()">Add barcodes</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card flat outlined>
      <v-card-title>
        LIST
        (<span class="green--text font-weight-bold">{{ eanList.filter(x => !x.used).length }} available to assign product</span>, <span class="red--text font-weight-bold ml-1">{{ eanList.filter(x => x.used).length }} with products assigned</span>)
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
          <tr v-for="(ean, eanK) in eanList" :key="eanK">
            <td>{{ ean.ean }}</td>
            <td v-if="ean.used">
              <span class="red--text">ASSIGNED</span>
            </td>
            <td v-else>
              <span class="green--text">FREE</span>
            </td>
            <td>
              <v-btn x-small color="red" dark @click="removeEan(ean, eanK)">DELETE</v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import functions from "../mixins/functions";
import globals from "../mixins/globals";

export default {
  name: "EanList",
  mixins: [functions, globals],
  data() {
    return {
      eanList: [],
      addBarcode: {
        list: ''
      }
    }
  },
  created() {
    this.getEans();
  },
  methods: {
    removeEan (ean, i) {
      this.$store.commit('showLoading');
      axios.post(this.globals.apiUrl + '/ean/list?remove', {
        ean: ean.id
      })
          .then(() => {
            this.notyf('Removed', 'success');
            this.eanList.splice(i, 1)
          })
          .catch(reason => {
            try {
              this.notyf(reason.response.data.message,' error')
            } catch (e) {
              this.notyf(reason,' error')
            }
          })
          .finally(() => {
            this.$store.commit('hideLoading');
          })
    },
    addBarcodes() {
      axios.post(this.globals.apiUrl + '/ean/list?add', {
        list: this.addBarcode.list
      })
      .then((res) => {
        this.notyf('Added '+res.data.added+' eans', 'success');
        this.getEans();
      })
      .catch(reason => {
        try {
          this.notyf(reason.response.data.message,' error')
        } catch (e) {
          this.notyf(reason,' error')
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    getEans() {
      this.$store.commit('showLoading');
      axios.get(this.globals.apiUrl + '/ean/list')
      .then((res) => {
        this.eanList = res.data
        this.$store.commit('hideLoading');
      })
    }
  }
}
</script>

<style scoped>

</style>
